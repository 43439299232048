<template>
  <div class="pei">
    <div>
      <div class="buttonbc">
        <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
          <img src="@/assets/back.png" alt="">
        </el-button>
      </div>
    </div>
    <en-table-layout ref="filterTable" :table-data="paramsData.data">
      <template slot="toolbar">

        <!-- <div class="toolbar-title">开票记录</div> -->
        <el-form-item label="申请时间">
          <el-date-picker style="width: 220px" v-model="daterangeDate" type="daterange" @change="daterangeDateChange"
            range-separator="-" size="small" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-form-item label="发票状态">
          <el-select v-model="params.status" size="small" style="width: 120px;" @change="inpuchange">
            <el-option label="全部" value></el-option>
            <el-option v-for="phase in phases" :key="phase.id" :label="phase.cname" :value="phase.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开票类型">
          <el-select v-model="params.receipt_type" style="width: 150px;" size="small">
            <el-option v-for="item in invoiceType" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input v-model="params.batch_sn" size="small" placeholder="请输入发票编号搜索"></el-input>
        </el-form-item>

        <el-button size="small" type="primary" @click="getHistoryList(1)" style="align-self: baseline;">搜索</el-button>

      </template>

      <template slot="table-columns">
        <el-table-column prop="receipt_amount" label="发票金额"></el-table-column>
        <el-table-column prop="batch_sn" label="发票编号"></el-table-column>
        <el-table-column prop="create_time" label="申请时间">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="billing_name" label="供应商（发票开具方）"></el-table-column>
        <el-table-column prop="shop_disable" label="开票类型">
          <template slot-scope="scope">
            {{ scope.row.receipt_type === 'VATOSPECIAL' ? '增值税专用发票' : $route.query.type == 7 ? '增值税普通发票' : '普通电子票' }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === -1">审核未通过</span>
            <span v-if="scope.row.status === 0">审核中</span>
            <span v-if="scope.row.status === 1">审核已通过</span>
            <span v-if="scope.row.status === 2">已邮寄</span>
            <span v-if="scope.row.status === 3">已成功</span>
            <span v-if="scope.row.status === 4">文件生成中</span>
            <div class="under-reason" v-if="scope.row.status === -1" @click="showUnderReason(scope.row)">(驳回原因)</div>
          </template>
        </el-table-column>
        <el-table-column prop="cz" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary"
              v-if="(scope.row.status > 0 && scope.row.receipt_type === 'ELECTRO') || (scope.row.billing_id == 66 && scope.row.receipt_type === 'VATOSPECIAL' && scope.row.file)"
              @click="downloadFile(scope.row)">导出
            </el-button>
            <el-button size="mini" type="primary" @click="handleView(scope.row.history_id, scope.row.type)">详情
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" background @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="paramsData.page_no" :page-sizes="MixinPageSizes"
        :page-size="paramsData.page_size" :layout="MixinTableLayout" :total="paramsData.data_total">
      </el-pagination>
    </en-table-layout>
    <!--未通过原因-->
    <el-dialog title="未通过原因" :visible.sync="isShowUnderReason" width="17%">
      <div align="center">{{ under_reason }}</div>
    </el-dialog>
  </div>
</template>
<script>
import * as API_Invoice from '@/api/invoice';
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: 'Invoicemangerjilu',
  components: { EnTableLayout },
  data () {
    return {
      is: '',
      paramsData: {
        data: []
      },
      total: 100,

      currentPage3: 1,
      phases: [
        {
          cname: '审核中',
          id: '0',
        },
        {
          cname: '审核未通过',
          id: '-1',
        },
        {
          cname: '待邮寄',
          id: '1',
        },
        {
          cname: '已邮寄',
          id: '2',
        },
        {
          cname: '已成功',
          id: '3',
        },
        {
          cname: '文件生成中',
          id: '4',
        }
      ],
      invoiceType: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 'VATOSPECIAL',
          name: '增值税专用发票'
        },
        {
          id: 'ELECTRO',
          name: '普通电子票'
        }
      ],
      formInline: {},
      params: {
        status: '',
        batch_sn: '',
        page_no: 1,
        page_size: 20,
      },
      daterangeDate: [],
      /** 是否显示拒绝原因 */
      isShowUnderReason: false,

      /** 拒绝原因 */
      under_reason: '',
    };
  },
  watch: {
    daterangeDate: {
      handler: 'daterangeDateChange',
      deep: true,
    },
  },
  created () {
    // this.getzdlist();
    if (this.$route.query.type == 7) {
      this.invoiceType.splice(2, 1, {
        id: 'VATORDINARY',
        name: '增值税普通发票'
      })
    }
    // 自有供应商去掉等待邮寄
    if (this.$route.query.isEownSupplier) {
      const list = [1, 3, 4]
      list.forEach(id => {
        const index = this.phases.findIndex(item => item.id == id)
        this.phases.splice(index, 1)
      })
    }
  },
  mounted () {
    // this.is = this.$route.query.is;
    this.is = 1; //供应商索取 发票  就是订单发票
    this.getHistoryList();
  },
  methods: {
    /** 显示拒绝原因 */
    showUnderReason (row) {
      this.isShowUnderReason = true
      this.under_reason = row.reason
    },
    downloadFile (row) {
      row.file.split(',').filter(item => { return item !== '' }).forEach(i => {
        window.open(i)
      })
    },
    getHistoryList (page_no) {
      let params = {
        ...this.params,
        type: this.$route.query.type || this.is,
      };
      page_no && (params.page_no = 1);
      API_Invoice.getHistoryList(params).then((res) => {
        this.paramsData = res;
      });
    },
    handlePageSizeChange (page_size) {
      this.params.page_size = page_size;
      this.getHistoryList();
    },
    handlePageCurrentChange (page_no) {
      this.params.page_no = page_no;
      this.getHistoryList();
    },
    handleView (id, type) {
      this.$router.push({
        path: `/supplier/auditHistoryDetailSeller`,
        query: {
          id,
          type,
        },
      });
    },

    getzdlist () {
    },

    inpuchange () {
      console.log(this.params);
      this.getzdlist();
    },
    daterangeDateChange (val) {

      if (this.daterangeDate && this.daterangeDate.length && this.daterangeDate.length > 0) {
        this.params.start_time = parseInt(
          this.daterangeDate[0].getTime() / 1000
        );
        this.params.end_time = parseInt(this.daterangeDate[1].getTime() / 1000);
      } else {
        this.params.start_time = '';
        this.params.end_time = '';
      }
      // this.params.start_time = val ? val[0] / 1000 : "";
      // this.params.end_time = val ? val[1] / 1000 : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.block {
  margin-top: 30px;
  text-align: center;
}

/*下架原因*/
.under-reason {
  color: red;
  cursor: pointer;
}

.pei {
  position: relative;
}

.buttonbc {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}
</style>
